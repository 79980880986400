<template>
    <div class="middle-main">
            <div class="middle-title">
                <div class="middle-title-left">
                    <div class="middle-title-left-logo"></div>
                    <div style="font-size:20px;font-weight:bold">携手筑梦，共创未来</div>
                </div>
                <div class="middle-title-right" v-if="$route.path">
                    <div :class=" $route.path == '/index' ? 'middle-path-active' : ''" @click="otherPage('index')">首页</div>
                    <div :class=" $route.path == '/company' ? 'middle-path-active' : ''" @click="otherPage('company')">公司介绍</div>
                    <div :class=" $route.path == '/product' ? 'middle-path-active' : ''" @click="otherPage('product')">产品中心</div>
                    <div :class=" $route.path == '/shipper' ? 'middle-path-active' : ''" @click="otherPage('shipper')">货主加入</div>
                    <div :class=" $route.path == '/driver' ? 'middle-path-active' : ''" @click="otherPage('driver')">司机加入</div>
                </div>
            </div>
            <div class="middle-right" v-if="false">
                <div style="border-bottom:1px solid">
                    <div class="middle-right-img">
                        <img src="../image/xinxi(1).png" alt="">
                    </div>
                    <div style="font-size:12px">在线咨询</div>
                </div>
                <div>
                    <div class="middle-right-img">
                        <img src="../image/dianhua(1).png" alt="">
                    </div>
                    <div style="font-size:12px">服务热线</div>
                </div>
            </div>
        </div>
</template>
<script>
export default {
    methods: {
        otherPage(val) {
           this.$router.push(val);
       }
    }
}
</script>
<style lang="css">
.middle-main {
    position: relative;
}
.middle-title {
    position: absolute;
    width: 70%;
    height: 70px;
    left: 15%;
    top: 30px;
    background-color: aliceblue;
    border-radius: 35px;
    display: flex;
    justify-content: space-between;
}
.middle-title .middle-title-left {
    width: 30%;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
}
.middle-title .middle-title-left .middle-title-left-logo {
    width: 50px;
    height: 25px;
    /* background-color: rgba(3, 93, 252, 1); */
    margin-left: 20px;
    margin-right: 5px;
    background: url(../image/logo-bj.png) no-repeat;
    background-size: 100% 100%;
}
.middle-title .middle-title-right {
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    padding-right:30px;
}
.middle-title .middle-title-right div {
    font-size: 18px;
}
.middle-title .middle-title-right div+div{
    margin-left:20px;
}
.middle-right {
    position: absolute;
    width: 80px;
    height: 140px;
    border-radius: 10px;
    top: 20px;
    right: 20px;
    background: rgba(64, 66, 87, 1);
    color: white;
    /* display: flex;
    flex-direction: column;
    justify-content: space-evenly; */
}
.middle-right div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50%;
    justify-content: center;
}
.middle-right .middle-right-img {
    height: 30px;
    width: 30px;
    /* background: white; */

}
.middle-right .middle-right-img img {
    height: 100%;
}
.middle-path-active{
    color: rgba(3, 93, 252, 1);
    font-weight: bold;
}
</style>
