<template>
<div class="container">
    <top-layer></top-layer>
    <div class="middle">
        <center-layer></center-layer>
    </div>
    <bottom-layer :active="active"></bottom-layer>
</div>
</template>

<script>
import topLayer from '../components/top.vue'
import bottomLayer from '../components/bottom.vue'
import centerLayer from '../components/center.vue'

export default {
    data() {
        return {
            active: true,
        }
    },
    components: {
        topLayer,
        bottomLayer,
        centerLayer
    },
    created() {
        
    },
    methods: {
    
    }
}
</script>

<style lang="css" scoped>
@import "../style/index.css";

</style>
